import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsBaseDirective } from '../analytics-base.directive';
import * as models from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-sector-allocation',
    templateUrl: './sector-allocation.component.html',
    standalone: false
})
export class SectorAllocationComponent extends AnalyticsBaseDirective implements OnInit {
    constructor() {
        super();

        this.groupPercentageAccessors['Basic Materials'] = security => security.portfolio.sectorBasicMaterials;
        this.groupPercentageAccessors['Consumer Cyclical'] = security => security.portfolio.sectorConsumerCyclical;
        this.groupPercentageAccessors['Financial Services'] = security => security.portfolio.sectorFinancialServices;
        this.groupPercentageAccessors['Real Estate'] = security => security.portfolio.sectorRealEstate;
        this.groupPercentageAccessors['Communication Services'] = security => security.portfolio.sectorCommunicationServices;
        this.groupPercentageAccessors['Energy'] = security => security.portfolio.sectorEnergy;
        this.groupPercentageAccessors['Industrials'] = security => security.portfolio.sectorIndustrials;
        this.groupPercentageAccessors['Technology'] = security => security.portfolio.sectorTechnology;
        this.groupPercentageAccessors['Consumer Defensive'] = security => security.portfolio.sectorConsumerDefensive;
        this.groupPercentageAccessors['Healthcare'] = security => security.portfolio.sectorHealthcare;
        this.groupPercentageAccessors['Utilities'] = security => security.portfolio.sectorUtilities;
    }

    @Input()
    set accounts(accounts: models.AccountAndHoldings[]) {
        this.myAccounts = accounts;
        this.loadAllocations(accounts, this.benchmarks);
    }

    get accounts(): models.AccountAndHoldings[] {
        return this.myAccounts;
    }

    @Input()
    set benchmarks(benchmarks: models.Security[]) {
        this.myBenchmarks = benchmarks;
        this.loadAllocations(this.accounts, benchmarks);
    }

    get benchmarks(): models.Security[] {
        return this.myBenchmarks;
    }
    private myBenchmarks: models.Security[] = [];
    private myAccounts: models.AccountAndHoldings[] = [];

    ngOnInit() {
    }

    protected loadGroupNamesAndAmounts(holding: models.AccountHolding, groupNamesAndAmounts: [string, number][]) {
        this.loadGroupNamesAndAmountsUsingPercentageAccessors(holding, groupNamesAndAmounts);
    }
}
