/* eslint-disable */
import { Component } from '@angular/core';
import { ConfigurationService } from './shared/core/configuration.service';

@Component({
    selector: 'app-footer',
    styles: [
        '.right { text-align: right; }'
    ],
    template: `
        <div class="layout-footer align-items-center p-4 shadow-2">
            <div class="grid">
                <div class="col-6">
                    Copyright &copy; {{copyright}}
                    <br />All Rights Reserved
                    <br />Justin Loadholtz
                </div>
                <div class="col-6 sm:col-6 right">
                    {{lastUpdated | date:'short'}}
                    <br />v{{version}}
                </div>
            </div>
        </div>
    `,
    standalone: false
})
export class AppFooterComponent {
    constructor(configuration: ConfigurationService) {
        const yearCreated = 2020;
        const currentYear = new Date().getFullYear();
        this.copyright = currentYear > yearCreated ? `${yearCreated} - ${currentYear}` : yearCreated.toString();
        this.lastUpdated = configuration.lastUpdated;
        this.version = configuration.version;
    }

    copyright: string;
    lastUpdated: Date;
    version: string;
}
