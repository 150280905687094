/* eslint-disable */
import {Component, OnInit} from '@angular/core';
import {AppComponent} from './app.component';

@Component({
    selector: 'app-menu',
    template: `
        <ul class="layout-menu">
            <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="false"></li>
        </ul>
    `,
    standalone: false
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppComponent) {}

    ngOnInit() {
        this.model = [
            { label: 'Accounts', icon: 'fas fa-university', routerLink: ['/accounts'] },
            { label: 'Analytics', icon: 'fas fa-chart-pie', routerLink: ['/accounts/analytics'] },
            { label: 'Securities', icon: 'fas fa-dollar-sign', routerLink: ['/securities'] },
            //{ label: 'Administration', icon: 'fas fa-tools', routerLink: ['/admin'] }
        ];
    }
}
