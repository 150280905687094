import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { SecuritiesProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { SecurityCompareService } from '../compare/security-compare.service';
import { finalize } from 'rxjs/operators';
import * as models from '../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
export enum SecurityReturnType {
    trailing = 'trailing',
    annual = 'annual'
}

@Component({
    selector: 'my-security-returns-table',
    templateUrl: './security-returns-table.component.html',
    standalone: false
})
export class SecurityReturnsTableComponent extends BaseComponentDirective {
    constructor(
        private securitiesProxy: SecuritiesProxy,
        private securityCompare: SecurityCompareService,
        private datePipe: DatePipe,
        private confirmationService: ConfirmationService) {
        super();
    }

    @Input()
    isBusy = false;
    returnColumns: SelectItem[] = [];
    private mySecurities: models.Security[] = [];
    private myReturnType: SecurityReturnType;
    private trailingReturnColumns: SelectItem[];
    private annualReturnColumns: SelectItem[];

    @Input()
    get returnType() {
        return this.myReturnType;
    }

    set returnType(returnType: SecurityReturnType) {
        this.myReturnType = returnType;
        this.initialize();
    }

    @Input()
    get securities() {
        return this.mySecurities;
    }

    set securities(securities: models.Security[]) {
        this.mySecurities = securities;
        this.initialize();
    }

    get columnCount() {
        return 4 + this.returnColumns.length;
    }

    shouldShowSecurityRowGroupHeader(rowIndex: number) {
        let show = rowIndex === 0;

        if(!show) {
            const previousRowIndex = rowIndex - 1;
            const previousHeader = previousRowIndex < this.securities.length
                ? this.securities[previousRowIndex].assetTypeDisplayText
                : undefined;
            const currentHeader = rowIndex < this.securities.length
                ? this.securities[rowIndex].assetTypeDisplayText
                : undefined;

            show = previousHeader !== currentHeader;
        }

        return show;
    }

    getReturnCssClass(security: models.Security, columnValue: number) {
        const value = this.getReturn(security, columnValue);
        return this.getFinancialReturnCssClass(value);
    }

    getReturn(security: models.Security, columnValue: any) {
        let ret: number;

        if(this.returnType === SecurityReturnType.annual) {
            const annualReturn = security.annualReturns.find(a => a.year === columnValue);
            ret = annualReturn ? annualReturn.totalReturn : undefined;
        }
        else if(security && security.trailingReturns) {
            ret = security.trailingReturns[columnValue];
        }

        return ret;
    }

    getTotalAssetsInBillions(security: models.Security) {
        return security.totalAssets
            ? (security.totalAssets / 1000000000).toFixed(2)
            : undefined;
    }

    getPriceAsOfTooltip(security: models.Security) {
        return this.getAsOfDateTooltip(security.priceAsOf, this.datePipe);
    }

    addToCompare(security: models.Security) {
        this.securityCompare.addSecurity(security);
    }

    removeFromCompare(security: models.Security) {
        this.securityCompare.removeSecurity(security.securityId);
    }

    showAddToCompareIcon(security: models.Security) {
        return !this.securityCompare.containsSecurity(security.securityId);
    }

    showRemoveFromCompareIcon(security: models.Security) {
        return this.securityCompare.containsSecurity(security.securityId);
    }

    confirmDeleteSecurity(security: models.Security, rowIndex: number) {
        this.confirmationService.confirm(
            {
                key: 'security-returns-table-component',
                header: 'Delete Security?',
                message: `Are you sure you want to delete ${security.displayText}?`,
                accept: () => {
                    this.deleteSecurity(security, rowIndex);
                }
            });
    }

    private initialize() {
        if(this.securities && this.securities.length > 0) {
            if(this.returnType === SecurityReturnType.annual) {
                this.ensureAnnualReturnColumns();
                this.returnColumns = this.annualReturnColumns;
            }
            else if(this.returnType === SecurityReturnType.trailing) {
                this.ensureTrailingReturnColumns();
                this.returnColumns = this.trailingReturnColumns;
            }
        }
    }

    private ensureTrailingReturnColumns() {
        if(this.trailingReturnColumns) {
            return;
        }

        const columns: SelectItem[] = [
            { value: 'oneDayReturn', label: '1 Day' },
            { value: 'oneWeekReturn', label: '1 Week' },
            { value: 'oneMonthReturn', label: '1 Month' },
            { value: 'threeMonthReturn', label: '3 Months' },
            { value: 'yearToDateReturn', label: 'YTD' },
            { value: 'oneYearReturn', label: '1 Year' },
            { value: 'threeYearReturn', label: '3 Years' },
            { value: 'fiveYearReturn', label: '5 Years' },
            { value: 'tenYearReturn', label: '10 Years' },
            { value: 'fifteenYearReturn', label: '15 Years' }
        ];

        this.trailingReturnColumns = columns;
    }

    private ensureAnnualReturnColumns() {
        if(this.annualReturnColumns) {
            return;
        }

        const years: {[year: number]: boolean} = {};

        this.securities.forEach(a => {
            a.annualReturns.forEach(r => {
                years[r.year] = true;
            });
        });

        const currentYear = new Date().getFullYear();

        this.annualReturnColumns = Object.keys(years).sort((a,b) => a < b ? -1 : 1).map(year => {
            const y = parseInt(year, 10);

            return {
                value: y,
                label: y === currentYear ? 'YTD' : year
            };
        });
    }

    private deleteSecurity(security: models.Security, rowIndex: number) {
        this.isBusy = true;
        this.securitiesProxy.deleteSecurity(security.securityId)
            .pipe(
                finalize(
                    () => {
                        this.isBusy = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.securityCompare.removeSecurity(security.securityId);
                    this.securities.splice(rowIndex, 1);
                });
    }
}
