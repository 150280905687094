import { Component, Input } from '@angular/core';
import { DatePipe, PercentPipe } from '@angular/common';
import { SelectItem } from 'primeng/api';
import * as models from '../../shared/swagger-codegen/models';

import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { BalanceHistoryDialogService } from '../balance-history-dialog/balance-history-dialog.service';
import { AccountHoldingWithAccount } from './account-list.component';

/* eslint-disable no-shadow */
export enum AccountReturnType {
    trailing = 'trailing',
    cumulative = 'cumulative',
    annual = 'annual'
}

@Component({
    selector: 'my-account-returns-table',
    templateUrl: './account-returns-table.component.html',
    styleUrls: ['./account-returns-table.component.scss'],
    standalone: false
})
export class AccountReturnsTableComponent extends BaseComponentDirective {
    constructor(
        private balanceHistoryDialogService: BalanceHistoryDialogService,
        private datePipe: DatePipe,
        private percentPipe: PercentPipe) {
        super();
    }

    @Input()
    isBusy = false;
    returnColumns: SelectItem[] = [];
    private myHoldings: AccountHoldingWithAccount[] = [];
    private myReturnType: AccountReturnType;
    private trailingReturnColumns: SelectItem[];
    private cumulativeReturnColumns: SelectItem[];
    private annualReturnColumns: SelectItem[];

    @Input()
    get returnType() {
        return this.myReturnType;
    }

    set returnType(returnType: AccountReturnType) {
        this.myReturnType = returnType;
        this.initialize();
    }

    @Input()
    get holdings() {
        return this.myHoldings;
    }

    set holdings(holdings: AccountHoldingWithAccount[]) {
        this.myHoldings = holdings;
        this.initialize();
    }

    get columnCount() {
        return 4 + this.returnColumns.length;
    }

    shouldShowSecurityPriceChangePercent(holding: AccountHoldingWithAccount) {
        return !this.shouldShowHoldingPrice(holding) && holding.security.priceChangePercent !== undefined;
    }

    shouldShowHoldingPrice(holding: AccountHoldingWithAccount) {
        return holding.securityAlternatePrice && holding.securityAlternatePrice.price;
    }

    shouldShowAccountRowGroupHeader(rowIndex: number) {
        let show = rowIndex === 0;

        if(!show) {
            const previousRowIndex = rowIndex - 1;
            const previousValue = previousRowIndex < this.holdings.length
                ? this.holdings[previousRowIndex].account.accountId
                : undefined;
            const currentValue = rowIndex < this.holdings.length
                ? this.holdings[rowIndex].account.accountId
                : undefined;

            show = previousValue !== currentValue;
        }

        return show;
    }

    getReturnCssClass(holding: AccountHoldingWithAccount, columnValue: number) {
        const value = this.getReturn(holding, columnValue);
        return this.getFinancialReturnCssClass(value);
    }

    getPriceAsOfTooltip(security: models.Security) {
        return this.getAsOfDateTooltip(security.priceAsOf, this.datePipe);
    }

    getReturn(holding: AccountHoldingWithAccount, columnValue: any) {
        let ret: number;

        if(this.returnType === AccountReturnType.annual) {
            const annualReturns = holding.accountHoldingId > 0
                ? holding.annualReturns
                : holding.account.annualReturns;
            const annualReturn = annualReturns.find(a => a.year === columnValue);
            ret = annualReturn ? annualReturn.totalReturn : undefined;
        }
        else {
            const trailingReturns = holding.accountHoldingId > 0
                ? holding.trailingReturns
                : holding.account.trailingReturns;
            ret = trailingReturns ? trailingReturns[columnValue] : undefined;
        }

        return ret;
    }

    openBalanceHistoryDialog(holding: AccountHoldingWithAccount) {
        const header = `${holding.account.name}: ${holding.security.displayText}`;
        this.balanceHistoryDialogService.open(holding.account.accountId, holding.security.securityId, header);
    }

    showInceptionDate(holding: AccountHoldingWithAccount, columnValue: any) {
        return typeof (columnValue) === 'string'
            && columnValue.startsWith('sinceInception')
            && this.getReturn(holding, columnValue) !== undefined
            && (holding.accountHoldingId > 0 || holding.account);
    }

    getInceptionDateDescription(holding: AccountHoldingWithAccount) {
        let fromDate: string;
        let thruDate: string;

        if(holding.accountHoldingId > 0) {
            fromDate = holding.firstTransactionDate;
            thruDate = holding.shares === 0 ? holding.lastTransactionDate : undefined;
        }
        else if(holding.account) {
            fromDate = holding.account.firstTransactionDate;
            thruDate = holding.account.balance === 0 ? holding.account.lastTransactionDate : undefined;
        }

        let description: string;

        if(fromDate && thruDate) {
            description = `${this.datePipe.transform(fromDate, 'shortDate')}`
                + `<br />to ${this.datePipe.transform(thruDate, 'shortDate')}`;
        }
        else if(fromDate) {
            description = `Since ${this.datePipe.transform(fromDate, 'shortDate')}`;
        }

        return description;
    }

    getResponsiveColumnTitle(column: SelectItem) {
        return column.label.replace('<br />', ' ');
    }

    getAllocationTooltip(holding: AccountHoldingWithAccount) {
        const purpose = holding.account.accountPurpose.name;
        const lines = [
            `% of Account: ${this.percentPipe.transform(holding.currentAccountAllocation, '1.1-1')}`,
            `% of Account Target: ${this.percentPipe.transform(holding.targetAllocation, '1.1-1')}`,
            `% of ${purpose}: ${this.percentPipe.transform(holding.currentAccountPurposeAllocation, '1.1-1')}`,
            `% of Total: ${this.percentPipe.transform(holding.currentOverallAllocation, '1.1-1')}`
        ];

        return lines.join('\n');
    }

    private initialize() {
        if(this.holdings && this.holdings.length > 0) {
            if(this.returnType === AccountReturnType.annual) {
                this.ensureAnnualReturnColumns();
                this.returnColumns = this.annualReturnColumns;
            }
            else if(this.returnType === AccountReturnType.cumulative) {
                this.ensureCumulativeReturnColumns();
                this.returnColumns = this.cumulativeReturnColumns;
            }
            else if(this.returnType === AccountReturnType.trailing) {
                this.ensureTrailingReturnColumns();
                this.returnColumns = this.trailingReturnColumns;
            }
        }
    }

    private ensureTrailingReturnColumns() {
        if(this.trailingReturnColumns) {
            return;
        }

        this.trailingReturnColumns = this.createReturnColumns(false);
    }

    private ensureCumulativeReturnColumns() {
        if(this.cumulativeReturnColumns) {
            return;
        }

        this.cumulativeReturnColumns = this.createReturnColumns(true);
    }

    private createReturnColumns(cumulative = false) {
        const columns: SelectItem[] = [
            { value: 'oneDayReturn', label: '1 Day' },
            { value: 'oneWeekReturn', label: '1 Week' },
            { value: 'oneMonthReturn', label: '1 Month' },
            { value: 'threeMonthReturn', label: '3 Months' },
            { value: 'sixMonthReturn', label: '6 Months' },
            { value: 'yearToDateReturn', label: 'YTD' },
            { value: 'oneYearReturn', label: '1 Year' },
            {
                value: cumulative ? 'threeYearCumulativeReturn' : 'threeYearReturn',
                label: '3 Years'
            },
            {
                value: cumulative ? 'fiveYearCumulativeReturn' : 'fiveYearReturn',
                label: '5 Years'
            },
            {
                value: cumulative ? 'tenYearCumulativeReturn' : 'tenYearReturn',
                label: '10 Years'
            },
            {
                value: cumulative ? 'sinceInceptionCumulativeReturn' : 'sinceInceptionReturn',
                label: 'Since<br />Inception'
            }
        ];

        return columns;
    }

    private ensureAnnualReturnColumns() {
        if(this.annualReturnColumns) {
            return;
        }

        const years: {[year: number]: boolean} = {};

        this.holdings.forEach(a => {
            a.annualReturns.forEach(r => {
                years[r.year] = true;
            });
        });

        const currentYear = new Date().getFullYear();

        this.annualReturnColumns = Object.keys(years).sort((a,b) => a < b ? -1 : 1).map(year => {
            const y = parseInt(year, 10);

            return {
                value: y,
                label: y === currentYear ? 'YTD' : year
            };
        });
    }
}
