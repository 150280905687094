import {Component, OnInit, ViewChild} from '@angular/core';
import { DatePipe } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { AccountsProxy, UsersProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { CurrentUserService } from '../../../shared/users/current-user.service';
import * as models from '../../../shared/swagger-codegen/models';
import {FileUpload} from "primeng/fileupload";

/* eslint-disable no-shadow */
enum ImportTypes {
    vanguardStatementImport,
    tiaaStatementImport = 1,
    fidelityStatementImport = 2
}

@Component({
    selector: 'my-transaction-import',
    templateUrl: './transaction-import.component.html',
    standalone: false
})
export class TransactionImportComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private usersProxy: UsersProxy,
        private accountsProxy: AccountsProxy,
        private currentUser: CurrentUserService,
        private datePipe: DatePipe) {

        super();

        const now = new Date();
        this.today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        this.reconciledThruDate = new Date(now.getFullYear(), now.getMonth(), 0);
    }

    isBusy = false;
    importTypes: SelectItem[] = [];
    importType: ImportTypes;
    accountId: number;
    filteredAccounts: SelectItem[] = [];
    filteredHoldings: models.AccountHolding[] = [];
    reconciledThruDate: Date;
    today: Date;
    showZeroBalanceHoldings = false;
    fileUploadLabel: string;
    fileUploadName: string;

    @ViewChild('fileUpload') fileUploadRef: FileUpload;
    private accounts: models.AccountAndHoldings[] = [];
    private holdings: models.AccountHolding[] = [];
    private vanguardAccountSelectItems: SelectItem[] = [];
    private tiaaAccountSelectItems: SelectItem[] = [];
    private fidelityAccountSelectItems: SelectItem[] = [];

    get importTypeIsSelected() {
        return this.importType !== undefined;
    }

    get importAccountIsSelected() {
        return this.accountId > 0;
    }

    get showStatementFileUpload() {
        return this.importTypeIsSelected && this.importAccountIsSelected;
    }

    get showHoldings() {
        return this.filteredHoldings.length > 0;
    }

    get isTIAAImport() {
        return this.importType === ImportTypes.tiaaStatementImport;
    }

    get isVanguardImport() {
        return this.importType === ImportTypes.vanguardStatementImport;
    }

    get isFidelityImport() {
        return this.importType === ImportTypes.fidelityStatementImport;
    }

    ngOnInit() {
        this.importTypes = [
            { value: ImportTypes.fidelityStatementImport, label: 'Fidelity Statement Import' },
            { value: ImportTypes.tiaaStatementImport, label: 'TIAA Statement Import' },
            { value: ImportTypes.vanguardStatementImport, label: 'Vanguard Statement Import' }
        ];
        this.loadAccounts();
    }

    getFileUploadUrl() {
        return `/api/accounts/${this.accountId}/statements`;
    }

    getFileUploadName() {
        let name: string;

        switch(this.importType) {
            case ImportTypes.fidelityStatementImport:
                name = 'fidelityFile';
                break;
            case ImportTypes.tiaaStatementImport:
                name = 'tiaaFile';
                break;
            case ImportTypes.vanguardStatementImport:
                name = 'vanguardFile';
                break;
        }

        return name;
    }

    onImportTypeChanged() {
        switch(this.importType) {
            case ImportTypes.fidelityStatementImport:
                this.fileUploadName = 'fidelityFile';
                this.fileUploadLabel = 'Choose Fidelity Statement File';
                this.filteredAccounts = this.fidelityAccountSelectItems;
                break;
            case ImportTypes.tiaaStatementImport:
                this.fileUploadName = 'tiaaFile';
                this.fileUploadLabel = 'Choose TIAA Statement File';
                this.filteredAccounts = this.tiaaAccountSelectItems;
                break;
            case ImportTypes.vanguardStatementImport:
                this.fileUploadName = 'vanguardFile';
                this.fileUploadLabel = 'Choose Vanguard Statement File';
                this.filteredAccounts = this.vanguardAccountSelectItems;
                break;
        }
    }

    onAccountChanged() {
        this.loadHoldings();
    }

    onBeforeFileUploaded() {
        this.filteredHoldings.length = 0;
    }

    onFileUploaded() {
        this.loadHoldings();
    }

    onFileUploadError() {
        this.fileUploadRef.clear();
    }

    showZeroBalanceHoldingsChanged() {
        this.filterHoldings();
    }

    hasHoldingsToReconcile() {
        return this.getHoldingsToReconcile().length > 0;
    }

    getRowCssClass(holding: models.AccountHolding) {
        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            'zero-balance-holding': holding.balance === 0
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    getReconciledSharesCssClass(holding: models.AccountHolding) {
        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            'positive-return': holding.shares === holding.reconciledShares,
            'negative-return': holding.shares !== holding.reconciledShares,
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }

    reconcile() {
        const holdingsToReconcile = this.getHoldingsToReconcile();

        if(holdingsToReconcile.length > 0) {
            const formattedDate = this.datePipe.transform(this.reconciledThruDate, 'yyyy-MM-dd');
            const accountHoldingModels: models.AccountHoldingReconciliation[] = holdingsToReconcile.map(h => {
                return {
                    accountHoldingId: h.accountHoldingId,
                    reconciledThruDate: formattedDate
                };
            });

            this.accountsProxy.reconcileAccountHoldings(accountHoldingModels)
                .subscribe(() => {
                    this.loadHoldings();
                });
        }
    }

    private loadAccounts() {
        this.isBusy = true;
        this.usersProxy.getUserAccountsAndHoldings(this.currentUser.userId)
            .pipe(
                finalize(() => this.isBusy = false),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.accounts = response.body;
                    this.vanguardAccountSelectItems = this.getAccountSelectItemsByInstitution(1);
                    this.tiaaAccountSelectItems = this.getAccountSelectItemsByInstitution(2);
                    this.fidelityAccountSelectItems = this.getAccountSelectItemsByInstitution(3);
                });
    }

    private getAccountSelectItemsByInstitution(institutionId: number) {
        const accountsForInstitution = this.accounts
            .filter(a => a.institution.institutionId === institutionId)
            .map(a => {
                return {
                    value: a.accountId,
                    label: a.name
                };
            });

        accountsForInstitution.sort((a,b) => a.label < b.label ? -1 : 1);

        return accountsForInstitution;
    }

    private loadHoldings() {
        this.accountsProxy.getAccountHoldings(this.accountId)
            .pipe(
                finalize(() => this.isBusy = false),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.holdings = response.body;
                    this.filterHoldings();
                });
    }

    private getHoldingsToReconcile() {
        return this.filteredHoldings.filter(h => (h as any).reconcile);
    }

    private filterHoldings() {
        this.filteredHoldings = this.showZeroBalanceHoldings
            ? this.holdings
            : this.holdings.filter(holding => holding.balance > 0 || holding.shares !== holding.reconciledShares);
    }
}
