import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsBaseDirective } from '../analytics-base.directive';
import * as models from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-holding-allocation',
    templateUrl: './holding-allocation.component.html',
    standalone: false
})
export class HoldingAllocationComponent extends AnalyticsBaseDirective implements OnInit {
    constructor() {
        super();
    }

    @Input()
    set accounts(accounts: models.AccountAndHoldings[]) {
        this.myAccounts = accounts;
        this.loadAllocations(accounts);
    }

    get accounts(): models.AccountAndHoldings[] {
        return this.myAccounts;
    }

    private myAccounts: models.AccountAndHoldings[] = [];

    ngOnInit() {
    }

    protected loadGroupNamesAndAmounts(holding: models.AccountHolding, groupNamesAndAmounts: [string, number][]) {
        if(holding.balance > 0) {
            groupNamesAndAmounts.push([holding.security.displayText, holding.balance]);
        }
    }
}
