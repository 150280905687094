import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsBaseDirective } from '../analytics-base.directive';
import * as models from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-market-cap-allocation',
    templateUrl: './market-cap-allocation.component.html',
    standalone: false
})
export class MarketCapAllocationComponent extends AnalyticsBaseDirective implements OnInit {
    constructor() {
        super();

        this.groupPercentageAccessors['Giant'] = security => security.portfolio.marketCapGiant;
        this.groupPercentageAccessors['Large'] = security => security.portfolio.marketCapLarge;
        this.groupPercentageAccessors['Medium'] = security => security.portfolio.marketCapMedium;
        this.groupPercentageAccessors['Small'] = security => security.portfolio.marketCapSmall;
        this.groupPercentageAccessors['Micro'] = security => security.portfolio.marketCapMicro;
    }

    @Input()
    set accounts(accounts: models.AccountAndHoldings[]) {
        this.myAccounts = accounts;
        this.loadAllocations(accounts, this.benchmarks);
    }

    get accounts(): models.AccountAndHoldings[] {
        return this.myAccounts;
    }

    @Input()
    set benchmarks(benchmarks: models.Security[]) {
        this.myBenchmarks = benchmarks;
        this.loadAllocations(this.accounts, benchmarks);
    }

    get benchmarks(): models.Security[] {
        return this.myBenchmarks;
    }
    private myBenchmarks: models.Security[] = [];
    private myAccounts: models.AccountAndHoldings[] = [];

    ngOnInit() {
    }

    protected loadGroupNamesAndAmounts(holding: models.AccountHolding, groupNamesAndAmounts: [string, number][]) {
        this.loadGroupNamesAndAmountsUsingPercentageAccessors(holding, groupNamesAndAmounts);
    }
}
