import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { SecuritiesProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import * as models from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-security-search',
    templateUrl: './security-search.component.html',
    standalone: false
})
export class SecuritySearchComponent extends BaseComponentDirective implements OnInit {
    constructor(private securitiesProxy: SecuritiesProxy) {
        super();
    }

    isBusy = false;
    selection: models.Security;
    securities: models.Security[] = [];

    @Output()
    securitySelected: EventEmitter<models.Security> = new EventEmitter<models.Security>();

    ngOnInit() {

    }

    searchSecurities(e: any) {
        this.isBusy = true;
        this.securitiesProxy.searchSecurities(e.query)
            .pipe(
                finalize(() => this.isBusy = false),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.securities = response.body;
                });
    }

    onSecuritySelected(security: models.Security) {
        this.securitySelected.emit(security);
    }
}
