import { Component, Input, OnInit } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { AccountAllocation } from './account-rebalance.component';

@Component({
    selector: 'my-rebalance-allocation-table',
    templateUrl: './rebalance-allocation-table.component.html',
    standalone: false
})
export class RebalanceAllocationTableComponent extends BaseComponentDirective implements OnInit {
    constructor(private percentPipe: PercentPipe) {
        super();
    }

    @Input()
    allocations: AccountAllocation[] = [];
    @Input()
    showBenchmark = true;

    ngOnInit() {
    }

    getCurrentDifferenceTooltip(allocation: AccountAllocation) {
        return this.getAllocationDifferenceTooltip(allocation.currentDifference,'Current');
    }

    getRebalanceDifferenceTooltip(allocation: AccountAllocation) {
        return this.getAllocationDifferenceTooltip(allocation.rebalanceDifference, 'Rebalance');
    }

    getBenchmarkDifferenceTooltip(allocation: AccountAllocation) {
        return this.getAllocationDifferenceTooltip(allocation.benchmarkDifference, 'VTSAX');
    }

    getPercentOfAssetClassTooltip(allocation: AccountAllocation) {
        if(allocation.isEquity || allocation.isBond) {
            return `% of Total ${(allocation.isBond ? 'Bond' : 'Stock' )} Allocation`;
        }

        return undefined;
    }

    private getAllocationDifferenceTooltip(difference: number, allocationDescription: string) {
        const percent = this.percentPipe.transform(Math.abs(difference), '1.2-2');
        const moreOrLess = difference < 0 ? 'LESS' : 'MORE';
        const prefix = `The ${allocationDescription} allocation has`;
        const suffix = `the Target allocation.`;

        return difference === 0
            ? `${prefix} the same as ${suffix}`
            : `${prefix} ${percent} ${moreOrLess} than ${suffix}`;
    }
}
