import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ChartData, ChartDataset, ChartOptions, TooltipItem, ScatterDataPoint } from 'chart.js';
import { AccountsProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import { ChartService } from '../../shared/ui/chart.service';

import {
    AccountHoldingBalanceHistory
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-balance-history-dialog',
    templateUrl: './balance-history-dialog.component.html',
    standalone: false
})
export class BalanceHistoryDialogComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private accountsProxy: AccountsProxy,
        private dynamicDialogConfig: DynamicDialogConfig,
        private chartService: ChartService,
        private currencyPipe: CurrencyPipe,
        private decimalPipe: DecimalPipe,
        private datePipe: DatePipe) {
        super();
    }

    chartData: ChartData;
    chartOptions: ChartOptions;

    ngOnInit() {
        const accountId: number = this.dynamicDialogConfig.data.accountId;
        const securityId: number = this.dynamicDialogConfig.data.securityId;

        this.createChartOptions();

        if(accountId && securityId) {
            this.accountsProxy.getAccountHoldingBalanceHistory(accountId, securityId)
                .pipe(this.takeUntilUnsubscribed())
                .subscribe(
                    response => {
                        this.createChartData(response.body);
                    });
        }
    }

    private createChartOptions() {
        const options = this.chartService.createDefaultChartOptions('line');
        options.scales.y.beginAtZero = false;

        options.scales.x = {
            type: 'timeseries',
            offset: true,
            gridLines: {
                display: false
            },
            ticks: {
                major: {
                    enabled: false
                },
                source: 'data',
                autoSkip: true,
                autoSkipPadding: 75,
                maxRotation: 0,
                sampleSize: 100
            }
        };

        options.plugins.tooltip.callbacks.title = this.getTooltipTitle.bind(this);
        options.plugins.tooltip.callbacks.label = this.getTooltipLabel.bind(this);
        options.scales.y.ticks.callback = this.getTick.bind(this);

        this.chartOptions = options;
    }

    private getTooltipTitle(tooltipItems: TooltipItem<'line'>[]) {
        return tooltipItems[0].dataset.label;
    }

    private getTooltipLabel(tooltipItem: TooltipItem<'line'>) {
        const dataSet = tooltipItem.dataset;
        const data = dataSet.data as any[];

        const date = tooltipItem.chart.data.labels[tooltipItem.dataIndex] as string;
        const dataItem = data[tooltipItem.dataIndex];
        const balance = this.currencyPipe.transform(dataItem.y);
        const price = this.currencyPipe.transform(
            dataItem.price,
            undefined,
            'symbol',
            '1.2-2'
        );
        const shares = this.decimalPipe.transform(
            dataItem.shares,
            '1.4-4'
        );

        return [
            ` ${balance} on ${date}`,
            ` ${shares} Shares @ ${price}`
        ];
    }

    private getTick(value: any) {
        const convertToThousands = value >= 1000;
        const amount = convertToThousands ? value / 1000 : value;
        const digitsInfo = convertToThousands ? '1.0-1' : '1.0-0';
        const currency = this.currencyPipe.transform(
            amount,
            undefined,
            'symbol',
            digitsInfo
        );

        return convertToThousands ? `${currency}K` : currency;
    }

    private createChartData(balanceHistory: AccountHoldingBalanceHistory[]) {
        const chartData: ChartData = {
            labels: [],
            datasets: this.createDatasets('Total Balance', 'Balance from Income')
        };

        let minYear = 999999;
        let maxYear = -999999;

        balanceHistory.forEach(h => {
            const date = new Date(h.date);
            chartData.labels.push(this.datePipe.transform(h.date, 'shortDate'));
            chartData.datasets[0].data.push(this.createDataPoint(date, h.balance, h.price, h.shares));
            chartData.datasets[1].data.push(this.createDataPoint(date, h.balanceFromIncome, h.price, h.sharesFromIncome));

            const year = new Date(h.date).getFullYear();
            minYear = Math.min(minYear, year);
            maxYear = Math.max(maxYear, year);
        });

        const data = chartData.datasets[1].data as any[];
        const income = data.find(d => d.y > 0);

        if(!income) {
            chartData.datasets.pop();
        }

        this.chartData = chartData;
        this.addYearSeparatorLineAnnotations(minYear, maxYear);
    }

    private createDataPoint(date: Date, balance: number, price: number, shares: number) {
        const dataPoint = {
            x: date.valueOf(),
            y: balance,
            price: price,
            shares: shares
        };

        return dataPoint as ScatterDataPoint;
    }

    private createDatasets(...labels: string[]) {
        const colors = this.chartService.getChartColors();
        const datasets: ChartDataset[] = [];

        labels.forEach((label, index) => {
            const dataset: ChartDataset = {
                label: label,
                backgroundColor: colors[index],
                borderColor: colors[index],
                data: [],
                fill: false,
                pointRadius: 0,
                hitRadius: 5,
                tension: 0,
                borderWidth: 2
            };

            datasets.push(dataset);
        });

        return datasets;
    }

    private addYearSeparatorLineAnnotations(minYear: number, maxYear: number) {
        for(let year = minYear + 1; year <= maxYear; year++) {
            this.chartOptions.plugins.annotation.annotations[`line-${year}`] = {
                type: 'line',
                scaleID: 'x',
                value: `${year}-01-01`,
                borderColor: '#b0bec5',
                borderWidth: 1,
                label: {
                    backgroundColor: '#00bcd4',
                    content: `${year}`,
                    enabled: true,
                    xAdjust: 20
                }
            };
        }
    }
}
