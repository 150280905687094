import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { CurrentUserService } from '../../shared/users/current-user.service';
import { UsersProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import * as models from '../../shared/swagger-codegen/models';

export interface AccountHoldingWithAccount extends models.AccountHolding {
    account: models.AccountAndHoldings;
    currentOverallAllocation?: number;
    currentAccountAllocation?: number;
    currentAccountPurposeAllocation?: number;
}

@Component({
    selector: 'my-account-list',
    templateUrl: './account-list.component.html',
    standalone: false
})
export class AccountListComponent extends BaseComponentDirective implements OnInit {
    constructor(private usersProxy: UsersProxy, private currentUser: CurrentUserService) {
        super();
    }

    isBusy = false;
    accounts: models.AccountAndHoldings[] = [];
    holdings: AccountHoldingWithAccount[] = [];
    filteredHoldings: AccountHoldingWithAccount[] = [];
    totalBalance: number;
    showClosedAccounts = false;
    showZeroBalanceHoldings = false;
    private totalBalanceByAccountPurpose: { [accountPurposeId: number]: number } = {};

    ngOnInit() {
        this.isBusy = true;
        this.loadAccounts();
    }

    showClosedAccountsChanged() {
        if(this.showClosedAccounts) {
            this.showZeroBalanceHoldings = true;
        }

        this.loadAccounts();
    }

    showZeroBalanceHoldingsChanged() {
        this.filterHoldings();
    }

    private loadAccounts() {
        this.usersProxy.getUserAccountsAndHoldings(this.currentUser.userId, this.showClosedAccounts)
            .pipe(
                finalize(() => this.isBusy = false),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.accounts = response.body;
                    this.filterHoldings();
                });
    }

    private filterHoldings() {
        this.holdings = this.flattenAccountsAndHoldings(this.accounts);
        this.filteredHoldings = this.applyFilterToHoldings(this.holdings);
    }

    private flattenAccountsAndHoldings(accounts: models.AccountAndHoldings[]) {
        const flattened: AccountHoldingWithAccount[] = [];
        this.totalBalance = 0;
        this.totalBalanceByAccountPurpose = {};

        accounts.forEach(account => {
            this.totalBalance += account.balance;

            if(!this.totalBalanceByAccountPurpose[account.accountPurpose.accountPurposeId]) {
                this.totalBalanceByAccountPurpose[account.accountPurpose.accountPurposeId] = 0;
            }

            this.totalBalanceByAccountPurpose[account.accountPurpose.accountPurposeId] += account.balance;

            account.holdings = account.holdings.sort((a,b) => {
                return a.balance > b.balance ? -1 : 1;
            });

            account.holdings.forEach(holding => {
                const flat = holding as AccountHoldingWithAccount;
                flat.account = account;
                flattened.push(flat);
            });

            const filteredAccountHoldings =  this.applyFilterToHoldings(account.holdings);

            if(filteredAccountHoldings.length > 1) {
                // Create a fake holding that has the account totals in it.
                const accountTotals: AccountHoldingWithAccount = {
                    balance: account.balance,
                    dividends: null,
                    shares: null,
                    security: {
                        name: '',
                        portfolio: null,
                        securityType: null,
                        ticker: null,
                        annualReturns: null,
                        trailingReturns: null,
                        nextPriceUpdate: null,
                        nextPriceHistoryUpdate: null,
                        nextMarketDataUpdate: null,
                        stockSplits: []
                    },
                    account: account,
                    securityAlternatePrice: null,
                    trailingReturns: null,
                    priceSource: null,
                    annualReturns: []
                };

                flattened.push(accountTotals);
            }
        });

        flattened.forEach(holding => {
            holding.currentOverallAllocation = holding.balance / this.totalBalance;
            holding.currentAccountAllocation = holding.balance / holding.account.balance;
            holding.currentAccountPurposeAllocation = holding.balance
                / this.totalBalanceByAccountPurpose[holding.account.accountPurpose.accountPurposeId];
        });

        return flattened;
    }

    private applyFilterToHoldings<T extends models.AccountHolding>(holdings: T[]) {
        return this.showZeroBalanceHoldings
            ? holdings
            : holdings.filter(h => h.balance > 0);
    }
}
