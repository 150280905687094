import { Component, Input, OnInit } from '@angular/core';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import * as models from '../../../shared/swagger-codegen/models';

interface StockExposure {
    name: string;
    payoffProfile: string;
    amount: number;
    percent: number;
    cusip: string;
    isin: string;
    ticker: string;
    security?: models.Security;
}

interface SecurityHoldingWithAmount extends models.SecurityHolding {
    amount: number;
}

@Component({
    selector: 'my-stock-exposure',
    templateUrl: './stock-exposure.component.html',
    standalone: false
})
export class StockExposureComponent extends BaseComponentDirective implements OnInit {
    constructor() {
        super();
    }

    @Input()
    set accounts(accounts: models.AccountAndHoldings[]) {
        this.myAccounts = accounts;
        this.loadStockExposure();
    }

    get accounts(): models.AccountAndHoldings[] {
        return this.myAccounts;
    }

    @Input()
    set securityHoldings(securityHoldings: { [securityId: number]: models.SecurityHolding[] }) {
        this.mySecurityHoldings = securityHoldings;
        this.loadStockExposure();
    }

    get securityHoldings() {
        return this.mySecurityHoldings;
    }

    get isFiltered() {
        return this.filter && this.filter.trim().length > 0;
    }

    filteredStockExposures: StockExposure[] = [];
    filter = '';
    isBusy = false;
    private stockExposures: StockExposure[] = [];
    private myAccounts: models.AccountAndHoldings[] = [];
    private mySecurityHoldings: { [securityId: number]: models.SecurityHolding[] } = {};

    ngOnInit() {
    }

    applyFilter() {
        if(this.isFiltered) {
            const filter = this.filter.trim().toLowerCase();

            this.filteredStockExposures = this.stockExposures
                .filter(s => s.name.toLowerCase().indexOf(filter) >= 0
                    || (s.ticker && s.ticker.toLowerCase().indexOf(filter) >= 0)
                    || (s.cusip && s.cusip.toLowerCase().indexOf(filter) >= 0)
                    || (s.isin && s.isin.toLowerCase().indexOf(filter) >= 0)
                );
        }
        else {
            this.filteredStockExposures = this.stockExposures;
        }
    }

    clearFilter() {
        this.filter = '';
        this.applyFilter();
    }

    getExposurePercentFormat(exposure: StockExposure) {
        let decimals = 2;
        let value = 0.0001;
        let format = '1.2-2';

        for(let i = 0; i < 15; i++) {
            if(exposure.percent > value) {
                format = `1.${decimals}-${decimals}`;
                break;
            }

            decimals++;
            value /= 10;
        }

        return format;
    }

    getExposureCurrencyFormat(exposure: StockExposure) {
        let decimals = 2;
        let value = 0.01;
        let format = '1.2-2';

        for(let i = 0; i < 15; i++) {
            if(exposure.amount >= value) {
                format = `1.${decimals}-${decimals}`;
                break;
            }

            decimals++;
            value /= 10;
        }

        return format;
    }

    private loadStockExposure() {
        try {
            this.isBusy = true;

            let total = 0;
            const securityHoldingsWithAmounts: { [key: string]: SecurityHoldingWithAmount } = {};
            const stockExposures: StockExposure[] = [];

            this.myAccounts.forEach(account => {
                total += account.balance;
                this.addSecurityHoldingTotals(account.holdings, securityHoldingsWithAmounts);
            });

            const stocks = this.getIndividualStockTotals();

            Object.keys(securityHoldingsWithAmounts)
                .forEach(key => {
                    const securityHolding = securityHoldingsWithAmounts[key];
                    const stockExposure: StockExposure = {
                        name: securityHolding.name,
                        payoffProfile: securityHolding.payoffProfile,
                        amount: securityHolding.amount,
                        percent: 0,
                        cusip: securityHolding.cusip,
                        isin: securityHolding.isin,
                        ticker: securityHolding.ticker
                    };

                    if(stockExposure.payoffProfile === 'Long') {
                        const i = stocks.findIndex(s => {
                            return s.security.cusip === securityHolding.cusip
                                || s.security.isin === securityHolding.isin
                                || s.security.ticker === securityHolding.ticker;
                        });

                        if(i >= 0) {
                            const stock = stocks[i];
                            stockExposure.amount += stock.amount;
                            stocks.splice(i, 1);
                        }
                    }

                    stockExposure.percent = stockExposure.amount / total;
                    stockExposures.push(stockExposure);
                });

            stocks.forEach(stock => {
                stockExposures.push({
                    name: stock.name,
                    payoffProfile: 'Long',
                    amount: stock.amount,
                    percent: stock.amount / total,
                    cusip: stock.security.cusip,
                    isin: stock.security.isin,
                    ticker: stock.security.ticker
                });
            });

            this.stockExposures = stockExposures
                .filter(exposure => exposure.amount > 0)
                .sort((a, b) => a.amount < b.amount ? 1 : -1);
        }
        finally {
            this.isBusy = false;
            this.applyFilter();
        }
    }

    private addSecurityHoldingTotals(
        accountHoldings: models.AccountHolding[],
        securityHoldingsWithAmounts: { [key: string]: SecurityHoldingWithAmount }) {

        accountHoldings.forEach(holding => {
                const securityHoldings = this.mySecurityHoldings[holding.security.securityId];

                if(securityHoldings) {
                    securityHoldings.forEach(securityHolding => {
                        const key = [
                            securityHolding.legalEntityIdentifier || 'N/A',
                            securityHolding.name || 'N/A',
                            securityHolding.payoffProfile || 'N/A'
                        ].join('|');

                        let holdingAmounts = securityHoldingsWithAmounts[key];

                        if(!holdingAmounts) {
                            holdingAmounts = {
                                securityHoldingId: 0,
                                legalEntityIdentifier: securityHolding.legalEntityIdentifier,
                                name: securityHolding.name,
                                payoffProfile: securityHolding.payoffProfile,
                                amount: 0,
                                percent: 0,
                                cusip: securityHolding.cusip,
                                isin: securityHolding.isin,
                                ticker: securityHolding.ticker
                            };

                            securityHoldingsWithAmounts[key] = holdingAmounts;
                        }

                        holdingAmounts.amount += holding.balance * securityHolding.percent;
                    });
                }
            });
    }

    private getIndividualStockTotals() {
        const individualStock = 3;
        const totals: { [securityId: number]: StockExposure } = {};

        this.myAccounts.forEach(account => {
            account.holdings
                .filter(holding => holding.security.securityType.securityTypeId === individualStock)
                .forEach(holding => {
                    let total = totals[holding.security.securityId];

                    if(!total) {
                        total = {
                            name: holding.security.name,
                            payoffProfile: 'Long',
                            amount: 0,
                            percent: 0,
                            cusip: holding.security.cusip,
                            isin: holding.security.isin,
                            ticker: holding.security.ticker,
                            security: holding.security
                        };

                        totals[holding.security.securityId] = total;
                    }

                    total.amount += holding.balance;
                });
        });

        return Object.keys(totals)
            .map(key => totals[key] as StockExposure);
    }
}
