import { Component, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { BaseComponentDirective } from '../../../shared/ui/base-component.directive';
import { AccountsProxy } from '../../../shared/server-proxies';
import { WhatIfService } from '../what-if/what-if.service';
import * as models from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-transaction-list',
    templateUrl: './transaction-list.component.html',
    standalone: false
})
export class TransactionListComponent extends BaseComponentDirective {
    constructor(private accountsProxy: AccountsProxy, private whatIf: WhatIfService) {
        super();
    }

    @Input()
    isBusy = false;
    accountId: number;
    securityId: number;
    transactionType: string;
    securityType: string;
    accountItems: SelectItem[] = [];
    holdingItems: SelectItem[] = [];
    transactionTypes: SelectItem[] = [];
    securityTypes: SelectItem[] = [];
    filteredTransactions: models.AccountHoldingTransaction[] = [];
    pageSize = 50;
    private myAccounts: models.AccountAndHoldings[] = [];
    private transactions: models.AccountHoldingTransaction[] = [];

    @Input()
    get accounts() {
        return this.myAccounts;
    }

    set accounts(accounts: models.AccountAndHoldings[]) {
        this.myAccounts = accounts;
        this.initialize();
    }

    get hasAnyFilter() {
        return this.hasHoldingFilter || this.hasSecurityTypeFilter || this.hasTransactionTypeFilter;
    }

    get hasHoldingFilter() {
        return this.securityId > 0;
    }

    get hasTransactionTypeFilter() {
        return this.transactionType && this.transactionType !== '*';
    }

    get hasSecurityTypeFilter() {
        return this.securityType && this.securityType !== '*';
    }

    get columnCount() {
        return 9;
    }

    get showPager() {
        return this.filteredTransactions.length > this.pageSize;
    }

    get canCompare() {
        return this.accountId > 0 && this.whatIf.transactionCount > 0;
    }

    onAccountChanged() {
        this.createAccountHoldingItems();
        this.loadTransactions();
    }

    onHoldingChanged() {
        this.filterTransactions();
    }

    onSecurityTypeChanged() {
        this.filterTransactions(false);
    }

    onTransactionTypeChanged() {
        this.filterTransactions(true, false);
    }

    addAllToCompare() {
        this.filteredTransactions
            .filter(t => this.isComparable(t))
            .forEach(t => this.addToCompare(t));
    }

    addToCompare(transaction: models.AccountHoldingTransaction) {
        this.whatIf.addTransaction(transaction);
    }

    removeFromCompare(transaction: models.AccountHoldingTransaction) {
        this.whatIf.removeTransaction(transaction.accountHoldingTransactionId);
    }

    showAddToCompareIcon(transaction: models.AccountHoldingTransaction) {
        return this.isComparable(transaction)
            && !this.whatIf.containsTransaction(transaction.accountHoldingTransactionId);
    }

    showRemoveFromCompareIcon(transaction: models.AccountHoldingTransaction) {
        return this.isComparable(transaction)
            && this.whatIf.containsTransaction(transaction.accountHoldingTransactionId);
    }

    private isComparable(transaction: models.AccountHoldingTransaction) {
        return transaction.type.toUpperCase() === 'BUY';
    }

    private initialize() {
        this.createAccountItems();
    }

    private createAccountItems() {
        const items = this.accounts.map(a => {
           return {
               value: a.accountId,
               label: `${a.institution.name}: ${a.name}`
           };
        });

        items.sort((a,b) => a.label > b.label ? 1 : -1);

        this.accountItems = items;
    }

    private createAccountHoldingItems() {
        const account = this.accounts.find(a => a.accountId === this.accountId);
        const holdings = account ? account.holdings : [];

        const items = holdings.map(h => {
           return {
               value: h.security.securityId,
               label: h.security.displayText
           };
        });

        items.sort((a,b) => a.label > b.label ? 1 : -1);

        this.holdingItems = [{ value: 0, label: 'ALL HOLDINGS' }].concat(items);
        this.securityId = 0;
    }

    private loadTransactions() {
        this.isBusy = true;
        this.accountsProxy.getAccountHoldingTransactions(this.accountId)
            .pipe(
                finalize(() => this.isBusy = false),
                this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.transactions = response.body;
                this.filterTransactions();
            });
    }

    private filterTransactions(updateSecurityTypes = true, updateTransactionTypes = true) {
        const transactions = this.hasAnyFilter
            ? this.transactions.filter(this.createTransactionFilter())
            : this.transactions;

        if(updateSecurityTypes) {
            this.updateSecurityTypes(transactions);
        }

        if(updateTransactionTypes) {
            this.updateTransactionTypes(transactions);
        }

        this.filteredTransactions = transactions;
    }

    private createTransactionFilter() {
        const filterFunctions: ((transaction: models.AccountHoldingTransaction) => boolean)[] = [];

        if(this.hasHoldingFilter) {
            filterFunctions.push((transaction: models.AccountHoldingTransaction) => {
                return transaction.accountHolding.security.securityId === this.securityId;
            });
        }

        if(this.hasSecurityTypeFilter) {
            filterFunctions.push((transaction: models.AccountHoldingTransaction) => {
                const type = transaction.accountHolding.security.securityType.pluralName.toUpperCase();
                return type === '*' || type === this.securityType;
            });
        }

        if(this.hasTransactionTypeFilter) {
            filterFunctions.push((transaction: models.AccountHoldingTransaction) => {
                const type = transaction.type.toUpperCase();
                return type === '*' || type === this.transactionType;
            });
        }

        return (transaction: models.AccountHoldingTransaction) => {
            return filterFunctions.every(filter => filter(transaction));
        };
    }

    private updateSecurityTypes(transactions: models.AccountHoldingTransaction[]) {
        const types = Array.from(new Set(transactions.map(t => t.accountHolding.security.securityType.pluralName)))
                .map(t => ({ value: t.toUpperCase(), label: t }));
        types.sort((a,b) => a.label < b.label ? -1 : 1);

        this.securityTypes = [{ value: '*', label: 'ALL TYPES' }].concat(types);

        if(!this.securityTypes.find(t => t.value === this.securityType)) {
            this.securityType = '*';
        }
    }

    private updateTransactionTypes(transactions: models.AccountHoldingTransaction[]) {
        const types = Array.from(new Set(transactions.map(t => t.type)))
                .map(t => ({ value: t.toUpperCase(), label: t }));
        types.sort((a,b) => a.label < b.label ? -1 : 1);

        this.transactionTypes = [{ value: '*', label: 'ALL TYPES' }].concat(types);

        if(!this.transactionTypes.find(t => t.value === this.transactionType)) {
            this.transactionType = '*';
        }
    }
}
