import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { SecuritiesProxy } from '../../shared/server-proxies';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';

import * as models from '../../shared/swagger-codegen/models';

/* eslint-disable no-shadow */
enum SecurityTypes {
    mutualFund = 1,
    etf = 2,
    stock = 3,
    moneyMarketFund = 4,
    collectiveInvestmentTrust = 5,
    marketIndex = 6
}

@Component({
    selector: 'my-security-detail',
    templateUrl: './security-detail.component.html',
    standalone: false
})
export class SecurityDetailComponent extends BaseComponentDirective implements OnInit {
    constructor(
        private securitiesProxy: SecuritiesProxy,
        private router: Router,
        private currentRoute: ActivatedRoute) {
        super();
    }

    isBusy = false;
    securityTypes: SelectItem[] = [];
    assetClasses: SelectItem[] = [
        { value: 'Equity', label: 'Equity' },
        { value: 'Fixed Income', label: 'Fixed Income' },
        { value: 'Balanced', label: 'Balanced' },
        { value: 'Money Market', label: 'Money Market' }
    ];
    security: models.Security;

    get isNewSecurity() {
        return !this.security || !this.security.securityId || this.security.securityId < 1;
    }

    get showCusip() {
        return !this.isSecurityType(SecurityTypes.marketIndex);
    }

    get showIsin() {
        return !this.isSecurityType(SecurityTypes.marketIndex) && !this.isNewSecurity;
    }

    get showMorningstarSecurityId() {
        return !this.isSecurityType(SecurityTypes.stock, SecurityTypes.moneyMarketFund) && !this.isNewSecurity;
    }

    get showMorningstarPerformanceId() {
        return !this.isSecurityType(SecurityTypes.moneyMarketFund) && !this.isNewSecurity;
    }

    get showAssetClass() {
        return !this.isNewSecurity;
    }

    get showEdgarClassId() {
        return this.isSecurityType(SecurityTypes.mutualFund, SecurityTypes.etf);
    }

    get edgarFundSearchUrl() {
        let url = 'https://www.sec.gov/cgi-bin/series';
        const securityName = this.security.name ? this.security.name.trim() : '';

        if(securityName.length > 0) {
            const words = securityName.split(/\s+/g);
            const search = (words.length > 2 ? words.slice(0, words.length - 2) : words).join(' ');
            url += `?company=${encodeURI(search)}`.replace(/%20/g, '+');
        }

        return url;
    }

    ngOnInit() {
        this.currentRoute.params.subscribe(params => {
            const securityId = parseInt(params['securityId'], 10);

            this.loadSecurityTypes();

            if(securityId > 0) {
                this.loadSecurity(securityId);
            }
        });
    }

    getSaveButtonText() {
        return this.security && this.security.securityId > 0 ? 'Save' : 'Add';
    }

    save() {
        if(this.security.expenseRatio) {
            this.security.expenseRatio /= 100;
        }

        this.securitiesProxy.saveSecurity(this.security)
            .subscribe(() => {
                this.router.navigateByUrl('/securities');
            });
    }

    onSecuritySelected(security: models.Security) {
        this.security = security;
    }

    private loadSecurityTypes() {
        this.securitiesProxy.getSecurityTypes()
            .subscribe(response => {
                this.securityTypes = response.body.map(t => ({ value: t.securityTypeId, label: t.name }));
            });
    }

    private loadSecurity(securityId: number) {
        this.isBusy = true;
        this.securitiesProxy.getSecurity(securityId)
            .pipe(
                finalize(() => this.isBusy = false),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.setSecurity(response.body);
                });
    }

    private setSecurity(security: models.Security) {
        if(security.expenseRatio) {
            security.expenseRatio *= 100;
        }

        this.security = security;
    }

    private isSecurityType(...types: SecurityTypes[]) {
        return this.security
            && this.security.securityType
            && types.some(t => t === this.security.securityType.securityTypeId);
    }
}
