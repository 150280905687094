import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { BaseComponentDirective } from '../../shared/ui/base-component.directive';
import * as models from '../../shared/swagger-codegen/models';

interface SecurityField {
    label: string;
    tooltip?: string;
    getValue(security: models.Security): any;
    formatValue?(value: any, security?: models.Security): string;
    getCssClass?(value: any, security?: models.Security): { [name: string]: boolean };
}

interface SecurityGroup {
    label: string;
    fields: SecurityField[];
    isVisible?(): boolean;
}

/* eslint-disable no-shadow */
enum SecurityTypes {
    mutualFund = 1,
    etf = 2,
    stock = 3,
    moneyMarketFund = 4,
    collectiveInvestmentTrust = 5,
    marketIndex = 6
}

@Component({
    selector: 'my-security-attributes',
    templateUrl: './security-attributes.component.html',
    standalone: false
})
export class SecurityAttributesComponent extends BaseComponentDirective implements OnChanges {
    constructor(
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe,
        private percentPipe: PercentPipe) {
        super();
    }

    @Input()
    securities: models.Security[] = [];
    @Input()
    enableDelete = false;
    @Output()
    securityDeleted = new EventEmitter<models.Security>();
    isBusy: boolean;
    filteredGroups: SecurityGroup[] = [];
    hasEquitySecurities = false;
    hasFixedIncomeSecurities = false;
    private groups: SecurityGroup[] = [];

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ngOnChanges(changes: SimpleChanges) {
        this.loadSecurities();
        this.initializeGroups();
    }

    removeFromCompare(security: models.Security) {
        this.securityDeleted.emit(security);
    }

    getFieldCssClass(field: SecurityField, security: models.Security) {
        const value = field.getValue(security);
        const css = field.getCssClass ? field.getCssClass(value, security) : { };
        css.center = true;
        return css;
    }

    getFormattedFieldValue(field: SecurityField, security: models.Security) {
        const value = field.getValue(security);
        return field.formatValue ? field.formatValue(value, security) : value;
    }

    private loadSecurities() {
        this.hasEquitySecurities = this.securities
            .filter(s => s.assetClass === 'Equity' || s.assetClass === 'Balanced').length > 0;
        this.hasFixedIncomeSecurities = this.securities
            .filter(s => s.assetClass === 'Fixed Income' || s.assetClass === 'Balanced').length > 0;
        this.filterGroups();
    }

    private initializeGroups() {
        const groups: SecurityGroup[] = [
            this.getDetailsGroup(),
            this.getPricingGroup(),
            this.getTrailingReturnsGroup(),
            this.getAnnualReturnsGroup(),
            this.getPortfolioCharacteristicsGroup(),
            this.getAssetAllocationGroup(),
            this.getEquityMarketCapGroup(),
            this.getEquityStockStylesGroup(),
            this.getEquityValuationGroup(),
            this.getEquityRegionGroup(),
            this.getEquitySectorGroup(),
            this.getFixedIncomeCreditQualityGroup(),
            this.getFixedIncomeSectorGroup(),
            this.getFixedIncomeStyleGroup(),
            this.getFixedIncomeCouponRangeGroup(),
            this.getFixedIncomeMaturityScheduleGroup()
        ];

        this.groups = groups;
        this.filterGroups();
    }

    private getDetailsGroup() {
        const group: SecurityGroup = {
            label: '',
            fields: [
                {
                    label: 'Name',
                    getValue: security => security.name
                },
                {
                    label: 'Ticker',
                    getValue: security => security.ticker
                },
                {
                    label: 'CUSIP',
                    getValue: security => security.cusip
                },
                {
                    label: 'ISIN',
                    getValue: security => security.isin
                },
                {
                    label: 'Type',
                    getValue: security => security.securityType.name
                },
                {
                    label: 'Asset Class',
                    getValue: security => security.assetClass
                },
                {
                    label: 'Category',
                    getValue: security => security.category
                },
                {
                    label: 'Morningstar Rating',
                    getValue: security => security.morningstarRating
                },
                {
                    label: 'Exchange',
                    getValue: security => security.exchange
                },
                {
                    label: 'Min Initial Investment',
                    getValue: security => security.securityType.securityTypeId === SecurityTypes.stock
                            || security.securityType.securityTypeId === SecurityTypes.etf
                        ? security.price
                        : security.minimumInitialInvestment,
                    formatValue: (value, security) => this.currencyPipe.transform(
                        value,
                        undefined,
                        undefined,
                        security.securityType.securityTypeId === SecurityTypes.mutualFund
                            || security.securityType.securityTypeId === SecurityTypes.moneyMarketFund
                                ? '1.0-0'
                                : undefined)
                },
                {
                    label: 'Expense Ratio',
                    getValue: security => security.expenseRatio,
                    formatValue: value => this.percentPipe.transform(value / 100.0, '1.2-2')
                },
                {
                    label: 'Dividend Yield',
                    getValue: security => security.dividendYield,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2')
                },
                {
                    label: 'Total Assets/Market Cap',
                    getValue: security => security.totalAssets ? security.totalAssets / 1000000000 : undefined,
                    formatValue: value => value ? this.currencyPipe.transform(value, undefined, undefined, '1.1-1') + ' Bil' : undefined
                },
                {
                    label: 'Primary Index',
                    getValue: security => security.primaryIndex
                },
                {
                    label: 'Prospectus Index',
                    getValue: security => security.prospectusIndex
                }
            ]
        };

        return group;
    }

    private getPricingGroup() {
        const group: SecurityGroup = {
            label: 'Pricing',
            fields: [
                {
                    label: 'Price',
                    getValue: security => security.price,
                    formatValue: value => this.currencyPipe.transform(value)
                },
                {
                    label: 'Price Change',
                    getValue: security => security.priceChange,
                    formatValue: value => this.currencyPipe.transform(value),
                    getCssClass: this.getPostiveOrNegativeReturnCssClassFunction.bind(this)
                },
                {
                    label: 'Price Change %',
                    getValue: security => security.priceChangePercent,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2'),
                    getCssClass: this.getPostiveOrNegativeReturnCssClassFunction.bind(this)
                },
                {
                    label: 'As Of',
                    getValue: security => security.priceAsOf,
                    formatValue: value => this.datePipe.transform(value + 'Z', 'shortDate')
                        + ' ' + this.datePipe.transform(value + 'Z', 'shortTime')
                },
                {
                    label: 'Price Relative to 52 Week High',
                    getValue: security => security.fiftyTwoWeekHigh,
                    formatValue: this.formatRelativeTo52WeekPrice.bind(this)
                },
                {
                    label: 'Price Relative to 52 Week Low',
                    getValue: security => security.fiftyTwoWeekLow,
                    formatValue: this.formatRelativeTo52WeekPrice.bind(this)
                },
                {
                    label: '52 Week High',
                    getValue: security => security.fiftyTwoWeekHigh,
                    formatValue: value => this.currencyPipe.transform(value)
                },
                {
                    label: '52 Week Low',
                    getValue: security => security.fiftyTwoWeekLow,
                    formatValue: value => this.currencyPipe.transform(value)
                }
            ]
        };

        return group;
    }

    private getAssetAllocationGroup() {
        const group: SecurityGroup = {
            label: 'Asset Allocation',
            fields: [
                {
                    label: 'Cash',
                    getValue: security => security.portfolio.assetsCash,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2')
                },
                {
                    label: 'US Stocks',
                    getValue: security => security.portfolio.assetsUsStocks,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2')
                },
                {
                    label: 'Non-US Stocks (Developed)',
                    getValue: security => security.portfolio.regionNonUsDeveloped || 0,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2')
                },
                {
                    label: 'Non-US Stocks (Emerging)',
                    getValue: security => security.portfolio.regionEmerging || 0,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2')
                },
                {
                    label: 'US Bonds',
                    getValue: security => security.category.toLowerCase().indexOf('world bond') === -1
                        ? security.portfolio.assetsBonds
                        : 0,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2')
                },
                {
                    label: 'Non-US Bonds',
                    getValue: security => security.category.toLowerCase().indexOf('world bond') >= 0
                        ? security.portfolio.assetsBonds
                        : 0,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2')
                },
                {
                    label: 'Other',
                    getValue: security => security.portfolio.assetsOther,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2')
                }
            ]
        };

        return group;
    }

    private getEquityMarketCapGroup() {
        const group: SecurityGroup = {
            label: 'Equity Market Capitalization',
            fields: [],
            isVisible: () => this.hasEquitySecurities
        };

        const fields = [
            {
                label: 'Giant',
                name: 'marketCapGiant'
            },
            {
                label: 'Large',
                name: 'marketCapLarge'
            },
            {
                label: 'Medium',
                name: 'marketCapMedium'
            },
            {
                label: 'Small',
                name: 'marketCapSmall'
            },
            {
                label: 'Micro',
                name: 'marketCapMicro'
            }
        ];

        fields.forEach(field => {
            group.fields.push({
                label: field.label,
                getValue: security => security.portfolio[field.name],
                formatValue: value => this.percentPipe.transform(value, '1.2-2')
            });
        });

        return group;
    }

    private getEquitySectorGroup() {
        const group: SecurityGroup = {
            label: 'Equity Sector Exposure',
            fields: [],
            isVisible: () => this.hasEquitySecurities
        };

        const fields = [
            {
                label: 'Basic Materials',
                name: 'sectorBasicMaterials',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Materials Sector encompasses a wide range of commodity-related manufacturing industries. Included in this sector are companies that manufacture chemicals, construction materials, glass, paper, forest products and related packaging products, and metals, minerals and mining companies, including producers of steel.'
            },
            {
                label: 'Consumer Cyclical',
                name: 'sectorConsumerCyclical',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Consumer Cyclical (aka Consumer Discretionary) Sector encompasses those industries that tend to be the most sensitive to economic cycles. Its manufacturing segment includes automotive, household durable goods, textiles & apparel and leisure equipment. The services segment includes hotels, restaurants and other leisure facilities, media production and services, and consumer retailing and services.'
            },
            {
                label: 'Financial Services',
                name: 'sectorFinancialServices',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Financial Sector contains companies involved in activities such as banking, mortgage finance, consumer finance, specialized finance, investment banking and brokerage, asset management and custody, corporate lending, insurance, financial investment, real estate investment trusts (REITs), as well as companies engaged in real estate management & development.'
            },
            {
                label: 'Real Estate',
                name: 'sectorRealEstate',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Real Estate Sector contains all REITs, with the exception of Mortgage REITs, (which remain in the Financial sector). Additionally, the sector includes Real Estate Management and Development services.'
            },
            {
                label: 'Communication Services',
                name: 'sectorCommunicationServices',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Communications Services Sector contains companies that provide communications services primarily through a fixed-line, cellular, wireless, high bandwidth and/or fiber optic cable network.'
            },
            {
                label: 'Energy',
                name: 'sectorEnergy',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Energy Sector comprises companies whose businesses are dominated by either of the following activities: The construction or provision of oil rigs, drilling equipment and other energy related service and equipment, including seismic data collection. Companies engaged in the exploration, production, marketing, refining and/or transportation of oil and gas products, coal and other consumable fuels.'
            },
            {
                label: 'Industrials',
                name: 'sectorIndustrials',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Industrials Sector includes companies whose businesses are dominated by one of the following activities: The manufacture and distribution of capital goods, including aerospace & defense, construction, engineering & building products, electrical equipment and industrial machinery. The provision of commercial services and supplies, including printing, employment, environmental and office services. The provision of transportation services, including airlines, couriers, marine, road & rail and transportation infrastructure.'
            },
            {
                label: 'Technology',
                name: 'sectorTechnology',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Technology Sector covers the following general areas: firstly, Technology Software & Services, including companies that primarily develop software in various fields such as the Internet, applications, systems, databases management and/or home entertainment, and companies that provide information technology consulting and services, as well as data processing and outsourced services; secondly Technology Hardware & Equipment, including manufacturers and distributors of communications equipment, computers & peripherals, electronic equipment and related instruments; and thirdly, Semiconductors & Semiconductor Equipment Manufacturers.'
            },
            {
                label: 'Consumer Defensive',
                name: 'sectorConsumerDefensive',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Consumer Defensive (aka Consumer Staples) Sector comprises companies whose businesses are less sensitive to economic cycles. It includes manufacturers and distributors of food, beverages and tobacco and producers of non-durable household goods and personal products. It also includes food & drug retailing companies as well as hypermarkets and consumer super centers.'
            },
            {
                label: 'Healthcare',
                name: 'sectorHealthcare',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Health Care Sector encompasses two main industry groups. The first includes companies who manufacture health care equipment and supplies or provide health care related services, including distributors of health care products, providers of basic health-care services, and owners and operators of health care facilities and organizations. The second regroups companies primarily involved in the research, development, production and marketing of pharmaceuticals and biotechnology products. The healthcare sector is one of the largest and most complex in the U.S. economy, accounting for close to a fifth of overall gross domestic product (GDP).'
            },
            {
                label: 'Utilities',
                name: 'sectorUtilities',
                /* eslint-disable-next-line max-len */
                tooltip: 'The Utilities Sector encompasses those companies considered electric, gas or water utilities, or companies that operate as independent producers and/or distributors of power. Investors typically treat utilities as long-term holdings and use them to inject steady income in their portfolios.\n\nUtilities typically offer investors stable and consistent dividends, coupled with less price volatility relative to the overall equity markets. Because of these facts, utilities tend to perform well during recessionary climates. Contrarily, utility stocks tend to fall out of favor with the market, during times of economic growth.'
            }
        ];

        fields.forEach(field => {
            group.fields.push({
                label: field.label,
                tooltip: field.tooltip,
                getValue: security => security.portfolio[field.name],
                formatValue: value => this.percentPipe.transform(value, '1.2-2')
            });
        });

        return group;
    }

    private getEquityStockStylesGroup() {
        const group: SecurityGroup = {
            label: 'Stock Styles',
            fields: [],
            isVisible: () => this.hasEquitySecurities
        };

        const fields = [
            {
                label: 'Large Value',
                name: 'stockStyleLargeValue'
            },
            {
                label: 'Large Blend',
                name: 'stockStyleLargeBlend'
            },
            {
                label: 'Large Growth',
                name: 'stockStyleLargeGrowth'
            },
            {
                label: 'Medium Value',
                name: 'stockStyleMediumValue'
            },
            {
                label: 'Medium Blend',
                name: 'stockStyleMediumBlend'
            },
            {
                label: 'Medium Growth',
                name: 'stockStyleMediumGrowth'
            },
            {
                label: 'Small Value',
                name: 'stockStyleSmallValue'
            },
            {
                label: 'Small Blend',
                name: 'stockStyleSmallBlend'
            },
            {
                label: 'Small Growth',
                name: 'stockStyleSmallGrowth'
            }
        ];

        fields.forEach(field => {
            group.fields.push({
                label: field.label,
                getValue: security => security.portfolio[field.name],
                formatValue: value => this.percentPipe.transform(value, '1.2-2')
            });
        });

        return group;
    }

    private getFixedIncomeSectorGroup() {
        const group: SecurityGroup = {
            label: 'Fixed Income Sector Exposure',
            fields: [],
            isVisible: () => this.hasFixedIncomeSecurities
        };

        const fields = [
            {
                label: 'Government',
                name: 'sectorGovernment'
            },
            {
                label: 'Municipal',
                name: 'sectorMunicipal'
            },
            {
                label: 'Corporate',
                name: 'sectorCorporate'
            },
            {
                label: 'Securitized',
                name: 'sectorSecuritized'
            },
            {
                label: 'Cash and Equivalents',
                name: 'sectorCash'
            },
            {
                label: 'Other',
                name: 'sectorOther'
            }
        ];

        fields.forEach(field => {
            group.fields.push({
                label: field.label,
                getValue: security => security.portfolio[field.name],
                formatValue: value => this.percentPipe.transform(value, '1.2-2')
            });
        });

        return group;
    }

    private getEquityRegionGroup() {
        const group: SecurityGroup = {
            label: 'Equity Region Exposure',
            fields: [],
            isVisible: () => this.hasEquitySecurities
        };

        const fields = [
            {
                label: 'North America',
                name: 'regionNorthAmerica'
            },
            {
                label: 'United Kingdom',
                name: 'regionUnitedKingdom'
            },
            {
                label: 'Latin America',
                name: 'regionLatinAmerica'
            },
            {
                label: 'Europe Developed',
                name: 'regionEuropeDeveloped'
            },
            {
                label: 'Europe Emerging',
                name: 'regionEuropeEmerging'
            },
            {
                label: 'Africa/Middle East',
                name: 'regionAfricaMiddleEast'
            },
            {
                label: 'Japan',
                name: 'regionJapan'
            },
            {
                label: 'Australasia',
                name: 'regionAustralasia'
            },
            {
                label: 'Asia Developed',
                name: 'regionAsiaDeveloped'
            },
            {
                label: 'Asia Emerging',
                name: 'regionAsiaEmerging'
            }
        ];

        fields.forEach(field => {
            group.fields.push({
                label: field.label,
                getValue: security => security.portfolio[field.name],
                formatValue: value => this.percentPipe.transform(value, '1.2-2')
            });
        });

        return group;
    }

    private getPortfolioCharacteristicsGroup() {
        const group: SecurityGroup = {
            label: 'Portfolio Characteristics',
            fields: [
                {
                    label: '# of Equity Holdings',
                    getValue: security => security.portfolio.numberOfEquityHoldings,
                    formatValue: value => this.decimalPipe.transform(value)
                },
                {
                    label: '# of Bond Holdings',
                    getValue: security => security.portfolio.numberOfBondHoldings,
                    formatValue: value => this.decimalPipe.transform(value)
                },
                {
                    label: '# of Other Holdings',
                    getValue: security => security.portfolio.numberOfOtherHoldings,
                    formatValue: value => this.decimalPipe.transform(value)
                },
                {
                    label: '% of Assets in Top 10 Holdings',
                    getValue: security => security.portfolio.percentOfAssetsInTop10,
                    formatValue: value => this.percentPipe.transform(value)
                },
                {
                    label: 'Turnover',
                    getValue: security => security.portfolio.turnover,
                    formatValue: value => this.percentPipe.transform(value),
                    /* eslint-disable-next-line max-len */
                    tooltip: 'Turnover is calculated as the value of all transactions (buying, selling) divided by two, then divided by a fund\'s total holdings. Essentially, mutual fund turnover typically measures the replacement of holdings in a mutual fund and is commonly presented to investors as a percentage over a one year period. If a fund has 100% turnover, it may be construed that the fund replaces all of its holdings over a 12-month period (it could also indicate the fund replaced 50% of its holdings twice within a year).'
                }
            ]
        };

        const riskFields = [
            {
                name: 'alpha',
                label: 'Alpha',
                /* eslint-disable-next-line max-len */
                tooltip: 'Alpha (α) is a term used in investing to describe an investment strategy\'s ability to beat the market, or it\'s "edge." Alpha is thus also often referred to as “excess return” or “abnormal rate of return,” which refers to the idea that markets are efficient, and so there is no way to systematically earn returns that exceed the broad market as a whole.\n\nBecause alpha represents the performance of a portfolio relative to a benchmark, it is often considered to represent the value that a portfolio manager adds to or subtracts from a fund\'s return.\n\nAn alpha of 1.0 means the investment outperformed its benchmark index by 1%. An alpha of -1.0 means the investment underperformed its benchmark index by 1%. If the alpha is zero, its return matched the benchmark.'
            },
            {
                name: 'beta',
                label: 'Beta',
                /* eslint-disable-next-line max-len */
                tooltip: 'Beta (β) is a measure of the volatility–or systematic risk–of a security or portfolio compared to the market as a whole. Beta data about an individual stock can only provide an investor with an approximation of how much risk the stock will add to a (presumably) diversified portfolio.\n\nThe baseline number for beta is one, which indicates that the security\'s price moves exactly as the market moves. A beta of less than 1 means that the security is less volatile than the market, while a beta greater than 1 indicates that its price is more volatile than the market. A negative beta means a security is inversely correlated to the market benchmark. If a stock\'s beta is 1.5, it is considered to be 50% more volatile than the overall market.'
            },
            {
                name: 'rSquared',
                label: 'R\u00B2',
                /* eslint-disable-next-line max-len */
                tooltip: 'R-squared is generally interpreted as the percentage of a fund or security\'s movements that can be explained by movements in a benchmark index. An R-squared of 100% means that all movements of a security (or other dependent variable) are completely explained by movements in the market benchmark.'
            },
            {
                name: 'sharpeRatio',
                label: 'Sharpe Ratio',
                /* eslint-disable-next-line max-len */
                tooltip: 'Sharpe Ratio is used to help investors understand the return of an investment compared to its risk. Generally, the greater the value of the Sharpe ratio, the more attractive the risk-adjusted return.'
            },
            {
                label: 'Standard Deviation',
                name: 'standardDeviation',
                /* eslint-disable-next-line max-len */
                tooltip: 'Standard deviation is a statistical measurement in finance that, when applied to the annual rate of return of an investment, sheds light on that investment\'s historical volatility. The greater the standard deviation of securities, the greater the variance between each price and the mean, which shows a larger price range. As a downside, the standard deviation calculates all uncertainty as risk, even when it’s in the investor\'s favor—such as above average returns.'
            }
        ];

        riskFields.forEach(field => {
            group.fields.push({
                label: field.label,
                tooltip: field.tooltip,
                getValue: security => security.portfolio[field.name],
                formatValue: value => this.decimalPipe.transform(value, '1.2-2')
            });
        });

        return group;
    }

    private getEquityValuationGroup() {
        const group: SecurityGroup = {
            label: 'Equity Valuation',
            fields: [
                {
                    label: 'Price/Earnings Ratio',
                    getValue: security => security.portfolio.priceEarningsRatio,
                    formatValue: value => this.currencyPipe.transform(value),
                    /* eslint-disable-next-line max-len */
                    tooltip: 'The price-earnings ratio (P/E ratio) relates a company\'s share price to its earnings per share. A high P/E ratio could mean that a company\'s stock is over-valued, or else that investors are expecting high growth rates in the future.'
                },
                {
                    label: 'Price/Forward Earnings Ratio',
                    getValue: security => security.portfolio.priceForwardEarningsRatio,
                    formatValue: value => this.currencyPipe.transform(value),
                    /* eslint-disable-next-line max-len */
                    tooltip: 'Forward P/E is a version of the ratio of price-to-earnings that uses forecasted earnings for the P/E calculation. Because forward P/E uses estimated earnings per share (EPS), it may produce incorrect or biased results if actual earnings prove to be different.'
                },
                {
                    label: 'Price/Book Ratio',
                    getValue: security => security.portfolio.priceBookRatio,
                    formatValue: value => this.currencyPipe.transform(value),
                    /* eslint-disable-next-line max-len */
                    tooltip: 'The P/B ratio measures the market\'s valuation of a company relative to its book value (the tangible net asset value of a company calculated as total assets minus intangible assets such as patents, goodwill, etc. and liabilities.) P/B ratios under 1 are typically considered solid investments by value investors.'
                },
                {
                    label: 'Price/Sales Ratio',
                    getValue: security => security.portfolio.priceSalesRatio,
                    formatValue: value => this.currencyPipe.transform(value),
                    /* eslint-disable-next-line max-len */
                    tooltip: 'The price-to-sales ratio takes a company\'s market capitalization and divides it by the company\'s total revenue over the past 12 months. This ratio can be effective in valuing growth stocks that have yet to turn a profit or have suffered a temporary setback. The price-to-sales ratio does not account for the debt on a company\'s balance sheet.'
                }
            ],
            isVisible: () => this.hasEquitySecurities
        };

        return group;
    }

    private getFixedIncomeStyleGroup() {
        const group: SecurityGroup = {
            label: 'Fixed Income Portfolio Characteristics',
            fields: [
                {
                    label: 'Avg Effective Duration',
                    getValue: security => security.portfolio.averageEffectiveDuration,
                    formatValue: value => this.decimalPipe.transform(value, '1.2-2'),
                    /* eslint-disable-next-line max-len */
                    tooltip: 'The duration is primarily used as a measure of a bond fund’s sensitivity to prevailing interest rates. It\'s defined as the weighted average of the payments an investor will receive over a period, discounted to the bond\'s present value.\n\nDuration, which is expressed in years, measures how much a bond\'s price will rise or fall when interest rates change. The longer the duration, the greater the bond\'s sensitivity to interest rate changes.'
                },
                {
                    label: 'Avg Effective Maturity',
                    getValue: security => security.portfolio.averageEffectiveMaturity,
                    formatValue: value => this.decimalPipe.transform(value, '1.2-2'),
                    /* eslint-disable-next-line max-len */
                    tooltip: 'A bond\'s maturity is the length of time until the principal must be paid back. So a 10-year bond will earn interest for 10 years from the date it is purchased. At the end of that time period the bond\'s principal is repaid to the owner of the bond and interest payments cease.'
                },
                {
                    label: 'Avg Credit Quality',
                    getValue: security => security.portfolio.averageCreditQuality
                },
                {
                    label: 'Avg Coupon',
                    getValue: security => security.portfolio.averageCoupon,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2'),
                    /* eslint-disable-next-line max-len */
                    tooltip: 'A coupon or coupon payment is the annual interest rate paid on a bond, expressed as a percentage of the face value and paid from issue date until maturity. Coupons are usually referred to in terms of the coupon rate (the sum of coupons paid in a year divided by the face value of the bond in question).'
                },
                {
                    label: 'Avg Price',
                    getValue: security => security.portfolio.averagePrice,
                    formatValue: value => this.currencyPipe.transform(value)
                },
                {
                    label: 'Yield to Maturity',
                    getValue: security => security.portfolio.yieldToMaturity,
                    formatValue: value => this.percentPipe.transform(value, '1.2-2'),
                    /* eslint-disable-next-line max-len */
                    tooltip: 'Yield to maturity (YTM) is the total return anticipated on a bond if the bond is held until it matures. Yield to maturity is considered a long-term bond yield but is expressed as an annual rate. In other words, it is the internal rate of return (IRR) of an investment in a bond if the investor holds the bond until maturity, with all payments made as scheduled and reinvested at the same rate.'
                }
            ],
            isVisible: () => this.hasFixedIncomeSecurities
        };

        return group;
    }

    private getFixedIncomeCreditQualityGroup() {
        const group: SecurityGroup = {
            label: 'Fixed Income Portfolio Credit Quality',
            fields: [],
            isVisible: () => this.hasFixedIncomeSecurities
        };

        const fields = [
            {
                name: 'creditQualityAaa',
                label: 'AAA',
                /* eslint-disable-next-line max-len */
                tooltip: 'An obligation rated \'AAA\' has the highest rating assigned by S&P Global Ratings. The obligor\'s capacity to meet its financial commitments on the obligation is extremely strong.'
            },
            {
                name: 'creditQualityAa',
                label: 'AA',
                /* eslint-disable-next-line max-len */
                tooltip: 'An obligation rated \'AA\' differs from the highest-rated obligations only to a small degree. The obligor\'s capacity to meet its financial commitments on the obligation is very strong.'
            },
            {
                name: 'creditQualityA',
                label: 'A',
                /* eslint-disable-next-line max-len */
                tooltip: 'An obligation rated \'A\' is somewhat more susceptible to the adverse effects of changes in circumstances and economic conditions than obligations in higher-rated categories. However, the obligor\'s capacity to meet its financial commitments on the obligation is still strong.'
            },
            {
                name: 'creditQualityBbb',
                label: 'BBB',
                /* eslint-disable-next-line max-len */
                tooltip: 'An obligation rated \'BBB\' exhibits adequate protection parameters. However, adverse economic conditions or changing circumstances are more likely to weaken the obligor\'s capacity to meet its financial commitments on the obligation.'
            },
            {
                name: 'creditQualityBb',
                label: 'BB',
                /* eslint-disable-next-line max-len */
                tooltip: 'Obligations rated \'BB\' are regarded as having significant speculative characteristics and are considered Non-investment grade bonds (aka junk bonds). An obligation rated \'BB\' is less vulnerable to nonpayment than other speculative issues. However, it faces major ongoing uncertainties or exposure to adverse business, financial, or economic conditions that could lead to the obligor\'s inadequate capacity to meet its financial commitments on the obligation.'
            },
            {
                name: 'creditQualityB',
                label: 'B',
                /* eslint-disable-next-line max-len */
                tooltip: 'Obligations rated \'B\' are regarded as having significant speculative characteristics and are considered Non-investment grade bonds (aka junk bonds). An obligation rated \'B\' is more vulnerable to nonpayment than obligations rated \'BB\', but the obligor currently has the capacity to meet its financial commitments on the obligation. Adverse business, financial, or economic conditions will likely impair the obligor\'s capacity or willingness to meet its financial commitments on the obligation.'
            },
            {
                name: 'creditQualityBelowB',
                label: 'Below B',
                /* eslint-disable-next-line max-len */
                tooltip: 'Obligations rated \'CCC\', \'CC\', and \'C\' are regarded as having significant speculative characteristics and are considered Non-investment grade bonds (aka junk bonds).\n\nAn obligation rated \'CCC\' is currently vulnerable to nonpayment and is dependent upon favorable business, financial, and economic conditions for the obligor to meet its financial commitments on the obligation.\n\nAn obligation rated \'CC\' is currently highly vulnerable to nonpayment. The \'CC\' rating is used when a default has not yet occurred but S&P Global Ratings expects default to be a virtual certainty, regardless of the anticipated time to default.\n\nAn obligation rated \'C\' is currently highly vulnerable to nonpayment, and the obligation is expected to have lower relative seniority or lower ultimate recovery compared with obligations that are rated higher.\n\nAn obligation rated \'D\' is in default or in breach of an imputed promise.'
            },
            {
                name: 'creditQualityNotRated',
                label: 'Not Rated'
            }
        ];

        fields.forEach(field => {
            group.fields.push({
                label: field.label,
                getValue: security => security.portfolio[field.name],
                formatValue: value => this.percentPipe.transform(value, '1.2-2'),
                tooltip: field.tooltip
            });
        });

        return group;
    }

    private getFixedIncomeCouponRangeGroup() {
        const group: SecurityGroup = {
            label: 'Fixed Income Portfolio Coupon Range',
            fields: [],
            isVisible: () => this.hasFixedIncomeSecurities
        };

        const fields = [
            { name: 'coupon0', label: '0% or Paid in Kind' },
            { name: 'coupon0To2', label: '0-2%' },
            { name: 'coupon2To4', label: '2-4%' },
            { name: 'coupon4To6', label: '4-6%' },
            { name: 'coupon6To8', label: '6-8%' },
            { name: 'coupon8To10', label: '8-10%' },
            { name: 'couponMoreThan10', label: '>10%' }
        ];

        fields.forEach(field => {
            group.fields.push({
                label: field.label,
                getValue: security => security.portfolio[field.name],
                formatValue: value => this.percentPipe.transform(value, '1.2-2')
            });
        });

        return group;
    }

    private getFixedIncomeMaturityScheduleGroup() {
        const group: SecurityGroup = {
            label: 'Fixed Income Portfolio Coupon Range',
            fields: [],
            isVisible: () => this.hasFixedIncomeSecurities
        };

        const fields = [
            { name: 'maturity1To3', label: '1-3 Years' },
            { name: 'maturity3To5', label: '3-5 Years' },
            { name: 'maturity5To7', label: '5-7 Years' },
            { name: 'maturity7To10', label: '7-10 Years' },
            { name: 'maturity10To15', label: '10-15 Years' },
            { name: 'maturity15To20', label: '15-20 Years' },
            { name: 'maturity20To30', label: '20-30 Years' },
            { name: 'maturity30Plus', label: '>30 Years' }
        ];

        fields.forEach(field => {
            group.fields.push({
                label: field.label,
                getValue: security => security.portfolio[field.name],
                formatValue: value => this.percentPipe.transform(value, '1.2-2')
            });
        });

        return group;
    }

    private getTrailingReturnsGroup() {
        const group: SecurityGroup = {
            label: 'Trailing Returns',
            fields: []
        };

        const fields = [
            { name: 'oneDayReturn', label: '1 Day' },
            { name: 'oneWeekReturn', label: '1 Week' },
            { name: 'oneMonthReturn', label: '1 Month' },
            { name: 'threeMonthReturn', label: '3 Months' },
            { name: 'yearToDateReturn', label: 'YTD' },
            { name: 'oneYearReturn', label: '1 Year' },
            { name: 'threeYearReturn', label: '3 Years' },
            { name: 'fiveYearReturn', label: '5 Years' },
            { name: 'tenYearReturn', label: '10 Years' },
            { name: 'fifteenYearReturn', label: '15 Years' }
        ];

        fields.forEach(field => {
            group.fields.push({
                label: field.label,
                getValue: security => security.trailingReturns[field.name],
                formatValue: value => this.percentPipe.transform(value, '1.2-2'),
                getCssClass: this.getPostiveOrNegativeReturnCssClassFunction.bind(this)
            });
        });

        return group;
    }

    private getAnnualReturnsGroup() {
        const group: SecurityGroup = {
            label: 'Annual Returns',
            fields: []
        };

        const years: {[year: number]: boolean} = {};

        this.securities.forEach(a => {
            a.annualReturns.forEach(r => {
                years[r.year] = true;
            });
        });

        const currentYear = new Date().getFullYear();

        group.fields = Object.keys(years)
            .sort((a,b) => a < b ? 1 : -1)
            .map(year => {
                const y = parseInt(year, 10);

                return {
                    label: y === currentYear ? 'YTD' : year,
                    getValue: security => this.getTotalAnnualReturn(security, y),
                    formatValue: value => this.percentPipe.transform(value, '1.2-2'),
                    getCssClass: this.getPostiveOrNegativeReturnCssClassFunction.bind(this)
                };
            });

        return group;
    }

    private filterGroups() {
        this.filteredGroups = this.groups.filter(g => !g.isVisible || g.isVisible());
    }

    private getTotalAnnualReturn(security: models.Security, year: number) {
        const annualReturn = security.annualReturns.find(r => r.year === year);
        return annualReturn ? annualReturn.totalReturn : undefined;
    }

    private formatRelativeTo52WeekPrice(value: any, security: models.Security) {
        const difference = security.price - value;
        const percentDifference = difference / security.price;

        return this.percentPipe.transform(percentDifference, '1.2-2');
    }

    private getPostiveOrNegativeReturnCssClassFunction(value: any) {
        /* eslint-disable @typescript-eslint/naming-convention */
        return {
            'positive-return': value > 0,
            'negative-return': value < 0
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}

